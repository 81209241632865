import React from 'react';
import FlexBox from '../components/atoms/FlexBox';
import TestBanner from '../components/molecules/WarningBanner/WarningBanner';
import Footer from '../components/organisms/Footer';
import Nav from '../components/organisms/Nav/Nav';

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <FlexBox
      className="layout"
      flexDirection="column"
      justifyContent="space-between"
    >
      {process.env.VITE_REACT_APP_NODE_ENV !== 'production' ? (
        <TestBanner />
      ) : null}
      <Nav />
      <main>{children}</main>
      <Footer />
    </FlexBox>
  );
};

export default Layout;
